import { useEffect, useState } from 'react';
import { Dropdown, IconButton, IDropdownOption, Stack } from '@fluentui/react';
import React = require('react');
import { Content } from './components/Constants';

interface ColumnSelectorProps {
  excelQrCodeSelectionGenerationLocation: string;
  selectedColumn: string | undefined;
  setSelectedColumn: (string: string | undefined) => void;

  selectedUrlColumn: string | undefined;
  setSelectedUrlColumn: (string: string | undefined) => void;

  setContentCalloutHeading: (string) => void;
  setContentCalloutBody: (string) => void;
  setContentCalloutTarget: (string) => void;
  setContentCalloutVisible: (boolean) => void;
}

const ColumnSelector = (props: ColumnSelectorProps) => {
  const [columnOptions, setColumnOptions] = useState<IDropdownOption[]>([]);

  const fetchExcelColumns = async () => {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getUsedRange();
      range.load('values, columnCount');

      await context.sync();

      const headers = range.values[0]; // Assume the first row contains column headers
      const options: IDropdownOption[] = headers.map((header, index) => {
        const columnLabel = header && header.toString() !== '#VALUE!' ? header.toString() : `Column ${index + 1}`;
        
        // Truncate the column label to 25 characters
        const truncatedLabel = columnLabel.length > 25 ? columnLabel.substring(0, 25) + '...' : columnLabel;
        
        return {
          key: `${index}`, // Unique key for each column
          text: truncatedLabel, // Use the truncated column label
        };
      });

      setColumnOptions(options);
    });
  };

  // Fetch column headers from Excel
  useEffect(() => {
    fetchExcelColumns().catch((error) => {
      console.error('Error loading Excel columns:', error);
    });
  }, []);

  // Periodic refresh of columns every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchExcelColumns(); // Auto refresh columns every 5 seconds
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const handleFirstColumnChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      props.setSelectedColumn(option.key as string); // Set the first selected column's key
      if (option.key === props.selectedUrlColumn) {
        props.setSelectedUrlColumn(undefined); // Clear second dropdown if same column selected
      }
    }
  };

  const handleSecondColumnChange = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      props.setSelectedUrlColumn(option.key as string); // Set the second selected column's key
      if (option.key === props.selectedUrlColumn) {
        props.setSelectedColumn(undefined); // Clear first dropdown if same column selected
      }
    }
  };

  const filteredColumnOptionsForFirstDropdown = columnOptions.filter(option => option.key !== props.selectedUrlColumn);
  const filteredColumnOptionsForSecondDropdown = columnOptions.filter(option => option.key !== props.selectedColumn);

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack horizontal verticalAlign={'end'}>
        <Stack.Item grow>
          <Dropdown
            label="Column to insert QR codes into"
            options={filteredColumnOptionsForFirstDropdown}
            required
            selectedKey={props.selectedColumn} // Control the selected key
            onChange={handleFirstColumnChange} // Handle selection change
            placeholder="Choose a column"
            styles={{
              dropdown: {
                width: '100%', // Make the dropdown take up 100% of the parent width
              },
              // Apply truncation to the dropdown items' text (Dropdown options)
              dropdownItem: {
                maxWidth: 'calc(100% - 40px)', // Adjust for any padding or icon in the dropdown
                textOverflow: 'ellipsis', // Truncate with ellipsis
                whiteSpace: 'nowrap', // Prevent text from wrapping
                overflow: 'hidden', // Hide the overflowed text
              },
            }}
          />
        </Stack.Item>

        <Stack.Item align="end">
          <IconButton id={"content-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
            props.setContentCalloutHeading(Content.AssistantCallouts.QrCodeColumn.Heading)
            props.setContentCalloutBody(Content.AssistantCallouts.QrCodeColumn.Description)
            props.setContentCalloutTarget("content-callout")
            props.setContentCalloutVisible(true)
          }} />
        </Stack.Item>
      </Stack>

      {props.excelQrCodeSelectionGenerationLocation === "inCell" && (
        <Stack horizontal verticalAlign={'end'}>
          <Stack.Item grow>
            <Dropdown
              label="Column to insert QR code URLs into"
              options={filteredColumnOptionsForSecondDropdown}
              required
              selectedKey={props.selectedUrlColumn} // Control the selected key
              onChange={handleSecondColumnChange} // Handle selection change
              placeholder="Choose a column"
              styles={{
                dropdown: {
                  width: '100%', // Make the dropdown take up 100% of the parent width
                },
                // Apply truncation to the dropdown items' text (Dropdown options)
                dropdownItem: {
                  maxWidth: 'calc(100% - 40px)', // Adjust for any padding or icon in the dropdown
                  textOverflow: 'ellipsis', // Truncate with ellipsis
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                  overflow: 'hidden', // Hide the overflowed text
                },
              }}
            />
          </Stack.Item>

          <Stack.Item align="end">
            <IconButton id={"url-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
              props.setContentCalloutHeading(Content.AssistantCallouts.QrCodeUrlColumn.Heading)
              props.setContentCalloutBody(Content.AssistantCallouts.QrCodeUrlColumn.Description)
              props.setContentCalloutTarget("url-callout")
              props.setContentCalloutVisible(true)
            }} />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default ColumnSelector;
