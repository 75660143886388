import App from "./components/App";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Spinner, initializeIcons } from '@fluentui/react';
/* global document, Office, module, require */

let isOfficeInitialized = false;

const title = "Personalised QR Code Generator";

const render = (Component) => {
  ReactDOM.render(
      <div>
        {!isOfficeInitialized && <Spinner style={{ marginTop: 10 }} title={title} label="Please open the add in inside an Office application to continue." />}
        {isOfficeInitialized && <Component title={title} isOfficeInitialized={isOfficeInitialized} />}
      </div>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */

Office.initialize = () => { }

Office.onReady()
  .then((value) => {
    initializeIcons()
    isOfficeInitialized = value.host != null && value.platform != null;
    // @ts-ignore
    plausible('pageview', { props: { host: value.host, platform: value.platform }});
    render(App);
  })

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
