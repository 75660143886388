import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface GeolocationContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const GeolocationContentFields = (props: GeolocationContentProps) => {
    // State variables for latitude, longitude, and altitude
    const [latitude, setLatitude] = React.useState("");
    const [longitude, setLongitude] = React.useState("");
    const [altitude, setAltitude] = React.useState("");

    useEffect(() => {
        // Generate the base URL for Google Maps or Apple Maps based on the coordinates
        let mapLink = "geo:";

        if (latitude && longitude) {
            // Google Maps link format
            mapLink += `${latitude},${longitude}`;

            if (altitude) {
                mapLink += `,${altitude}`;
            }

            props.setContent(mapLink);
        }
    }, [latitude, longitude, altitude]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Latitude" 
                    value={latitude} 
                    onChange={(_e, newValue) => setLatitude(newValue)} 
                    placeholder="Enter latitude" 
                />
                <TextField 
                    label="Longitude" 
                    value={longitude} 
                    onChange={(_e, newValue) => setLongitude(newValue)} 
                    placeholder="Enter longitude" 
                />
                <TextField 
                    label="Altitude (Optional)" 
                    value={altitude} 
                    onChange={(_e, newValue) => setAltitude(newValue)} 
                    placeholder="Enter altitude (optional)" 
                />
            </Stack.Item>
        </Stack>
    );
};