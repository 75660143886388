import * as React from "react";
import { Link, DefaultButton, PrimaryButton, MessageBar, Spinner, IconButton, ButtonType, TextField, Text, Dropdown, IDropdownOption, SpinButton, Toggle, Stack, IStackTokens, getColorFromString, Pivot, PivotItem, MessageBarType } from "@fluentui/react";
import { ColorPickerPanel } from './ColorPickerPanel';
// images references in the manifest
import "../../../assets/icon-16.png";
import "../../../assets/icon-32.png";
import "../../../assets/icon-64.png";
import "../../../assets/icon-80.png";
import "../../../assets/icon-128.png";
import { ContentCallout } from "./ContentCallout";
import { Content } from "./Constants";
import { PremiumOptions } from "./PremiumOptions";
/* global Word */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
}

const options: IDropdownOption[] = [
  { key: 'l', text: 'Level L (~7% correction ability)' },
  { key: 'm', text: 'Level M (~15% correction ability)' },
  { key: 'q', text: 'Level Q (~25% correction ability)', selected: true },
  { key: 'H', text: 'Level H (~30% correction ability)' }
];

const fileFormatOptions: IDropdownOption[] = [
  { key: 'jpeg', text: 'JPEG', selected: true },
  { key: 'png', text: 'PNG' }
];

const stackTokens: IStackTokens = { childrenGap: 10 };

export default function App() {
  const [content, setContent] = React.useState("");
  const [size, setSize] = React.useState(200);
  const [enableMargin, setEnableMargin] = React.useState(false);
  const [fileFormat, setFileFormat] = React.useState(fileFormatOptions[0].key);
  const [errorCorrectionLevel, setErrorCorrectionLevel] = React.useState(options[2].key);
  const [primaryColor, setPrimaryColor] = React.useState(getColorFromString('#000')!);
  const [secondaryColor, setSecondaryColor] = React.useState(getColorFromString('#fff')!);
  const [showColorPicker, setShowColorPicker] = React.useState(null);

  const [headline, setHeadline] = React.useState("");
  const [subheadline, setSubheadline] = React.useState("");

  const [globalErrorMessage, setGlobalErrorMessage] = React.useState("");
  const [showGlobalErrorMessage, setShowGlobalErrorMessage] = React.useState(false);

  const [hideCustomIcon, setHideCustomIcon] = React.useState(false);

  const [licenseKey, setLicenseKey] = React.useState(localStorage.getItem('licenseKey') ?? "");
  const [hasVerifiedLicense, setHasVerifiedLicense] = React.useState(localStorage.getItem('licenseKey') != undefined && localStorage.getItem('emailAddress') != undefined);
  const [attachmentFileName, setAttachmentFileName] = React.useState("");

  const validateData = () => {
    setShowGlobalErrorMessage(false)
    setGlobalErrorMessage("")

    if (content == null || content.trim().length <= 0) {
      setContentErrorMessage("Please provide content for the QR Code")
      return false;
    }

    setContentErrorMessage("")
    return true;

  }

  const fetchImage = async (content: string) => {
    var license = licenseKey ?? "";

    var url = 'https://qrcodegenerator.api.littleappy.co/qrcode/basic?content=' + encodeURIComponent(content)
      + '&fileType=' + fileFormat
      + '&errorCorrectionLevel=' + errorCorrectionLevel
      + '&primaryColor=' + encodeURIComponent('#' + primaryColor.hex)
      + '&secondaryColor=' + encodeURIComponent('#' + secondaryColor.hex)
      + '&size=' + size
      + '&enableMargin=' + enableMargin
      + '&heading=' + headline
      + '&subtitle=' + subheadline
      + '&license=' + license
      + '&disableIcon=' + hideCustomIcon
      + '&platform=office'

    return await fetch(url)
      .then(response => {
        return response.blob()
      })
      .then(async blob => {
        return new Promise((resolve) => {
          var reader = new FileReader()
          reader.onloadend = async () => {
            var image = reader.result.toString().replace(/^data:.+;base64,/, '');
            resolve(image);
          }
          reader.readAsDataURL(blob)
        })
      })
  }

  const generateImage = async (context, host) => {
    await fetchImage(content)
    .then(async (image: string) => {
      if (host == Office.HostType.Word && Office.context.requirements.isSetSupported('WordApi', '1.1')){
        context.document.body.insertInlinePictureFromBase64(image, "End");
      } else if (host == Office.HostType.PowerPoint && Office.context.requirements.isSetSupported('ImageCoercion', '1.1')){
        Office.context.document.setSelectedDataAsync(image, {
          coercionType: Office.CoercionType.Image
        });
      } else if (host == Office.HostType.Excel && Office.context.requirements.isSetSupported('ExcelApi', '1.9')){
        var currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
        currentWorksheet.shapes.addImage(image);
      } else if (host == Office.HostType.Outlook && Office.context.requirements.isSetSupported('Mailbox', '1.8')){
        const fileName = attachmentFileName.trim().length <= 0 ? Date().toString() :  encodeURI(attachmentFileName);
        var fileFormatExtension: string;

        if (fileFormat == 'jpeg'){
          fileFormatExtension = 'jpg'
        } else {
          fileFormatExtension = 'png'
        }

        Office.context.mailbox.item.addFileAttachmentFromBase64Async(image, `${fileName}.${fileFormatExtension}`, { isInline: false });
        //No context to sync for Outlook
        return;
      } else {
        throw new Error("This Office client doesn't support generating QR codes. Please update to a newer version, or try using the web version.")
      }

      return await context.sync();
    })
  }

  const insertImage = async () => {
    var host = Office.context.host;
    if (host == Office.HostType.PowerPoint){
      return PowerPoint.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Word){
      return Word.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Excel){
      return Excel.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Outlook){
      return await generateImage(undefined, host);
    }

    return;
  };

  const [contentCalloutVisible, setContentCalloutVisible] = React.useState(false);
  const [contentCalloutHeading, setContentCalloutHeading] = React.useState("");
  const [contentCalloutTarget, setContentCalloutTarget] = React.useState("");
  const [contentCalloutBody, setContentCalloutBody] = React.useState("");
  const [contentErrorMessage, setContentErrorMessage] = React.useState("");

  const [selectedKey, setPivotKey] = React.useState("free");

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div className="ms-Grid" dir="ltr">
      <Stack tokens={stackTokens}>
        <Text>Customise the following settings to generate your personalized QR code.</Text>

        {isLoading && <div>
          <Spinner label="Generating QR code..." ariaLive="assertive" labelPosition="bottom" />
        </div>}

        { !isLoading && showGlobalErrorMessage && <MessageBar messageBarType={MessageBarType.error}>
          {globalErrorMessage}
        </MessageBar>}

        { !isLoading && !hasVerifiedLicense && <MessageBar messageBarType={MessageBarType.warning}>
            Add a license key to remove the watermark, add a custom logo, add a headline and more. <br/><Link href="https://littleappy.co/products/qr-code-generator/integrations/microsoft-office?utm_medium=display&utm_source=office365App&utm_campaign=bannerCta" target="_blank" onClick={() => { setPivotKey('premium') }} underline>Purchase license key</Link> | <Link style={{marginTop: 10}} onClick={() => { setPivotKey('premium') }} underline>Set license key</Link>
        </MessageBar>}

        { !isLoading &&
        <Pivot style={{ marginBottom: 50 }} linkSize="large" selectedKey={selectedKey} onLinkClick={(pivot) => { setPivotKey(pivot.props.itemKey) }}>
          <PivotItem itemKey={'free'} headerText="Free options">
            <div style={{ marginTop: 10 }}>
              <Stack tokens={stackTokens}>
                <Stack horizontal verticalAlign={'end'}>
                  <Stack.Item grow>
                    <TextField errorMessage={contentErrorMessage} placeholder="https://littleappy.co" label="QR code content" required value={content} onChange={(_e, newValue) => { setContent(newValue) }} />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton id={"content-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.Content.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.Content.Description)
                      setContentCalloutTarget("content-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>
                <Stack horizontal verticalAlign={'end'}>
                  <Stack.Item grow>
                    <Dropdown
                      required
                      defaultSelectedKey={fileFormat}
                      label="File format"
                      options={fileFormatOptions}
                      onChange={(_e, option) => { setFileFormat(option.key as string) }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton id={"fileformat-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.FileFormat.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.FileFormat.Description)
                      setContentCalloutTarget("fileformat-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>
                <Stack horizontal verticalAlign={'end'}>
                  <Stack.Item grow>
                    <Dropdown
                      required
                      label="Error correction level"
                      defaultSelectedKey={errorCorrectionLevel}
                      options={options}
                      onChange={(_e, option) => { setErrorCorrectionLevel(option.key as string) }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton id={"ecc-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.ErrorCorrectionLevel.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.ErrorCorrectionLevel.Description)
                      setContentCalloutTarget("ecc-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>

                <Stack horizontal verticalAlign={'end'}>
                  <Stack.Item grow>
                    <SpinButton
                      label="Size"
                      defaultValue={`${size}`}
                      min={100}
                      max={1500}
                      step={1}
                      incrementButtonAriaLabel="Increase value by 1"
                      decrementButtonAriaLabel="Decrease value by 1"
                      onChange={(_e, option) => { setSize(Number(option)) }}
                    />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton id={"size-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.Size.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.Size.Description)
                      setContentCalloutTarget("size-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>

                <Stack horizontal verticalAlign={'end'}>
                  <Stack.Item grow>
                    <Toggle label="Enable margin" defaultChecked={enableMargin} onText="On" offText="Off" onChange={(_e, enableMargin) => { setEnableMargin(enableMargin) }} />
                  </Stack.Item>
                  <Stack.Item align="end">
                    <IconButton id={"margins-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.Margins.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.Margins.Description)
                      setContentCalloutTarget("margins-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>

                <Stack tokens={stackTokens} horizontal verticalAlign={'center'}>
                  <div style={{ width: "40px", height: "40px", background: '#' + primaryColor.hex, border: '1px solid #000'}}></div>
                  <Stack.Item grow>
                    <DefaultButton
                      text="Select primary color"
                      onClick={() => setShowColorPicker("primary")} />
                  </Stack.Item>
                  <Stack.Item>
                    <IconButton id={"primarycolor-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.PrimaryColor.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.PrimaryColor.Description)
                      setContentCalloutTarget("primarycolor-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>

                <Stack tokens={stackTokens} horizontal verticalAlign={'center'}>
                  <div style={{ width: "40px", height: "40px", background: '#' + secondaryColor.hex, border: '1px solid #000'}}></div>
                  <Stack.Item grow>
                    <DefaultButton
                      text="Select background color"
                      onClick={() => setShowColorPicker("secondary")} />
                  </Stack.Item>
                  <Stack.Item>
                    <IconButton id={"bgcolor-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                      setContentCalloutHeading(Content.AssistantCallouts.BackgroundColor.Heading)
                      setContentCalloutBody(Content.AssistantCallouts.BackgroundColor.Description)
                      setContentCalloutTarget("bgcolor-callout")
                      setContentCalloutVisible(true)
                    }} />
                  </Stack.Item>
                </Stack>

              </Stack>
            </div>
          </PivotItem>
          <PivotItem itemKey={'premium'} headerText="Premium options">
            <PremiumOptions headline={headline} setHeadline={setHeadline} subHeadline={subheadline} setSubheadline={setSubheadline} setContentCalloutBody={setContentCalloutBody} setContentCalloutHeading={setContentCalloutHeading} 
            setContentCalloutTarget={setContentCalloutTarget} setContentCalloutVisible={setContentCalloutVisible} setLicenseKey={setLicenseKey} licenseKey={licenseKey}
            setHasVerifiedLicense={setHasVerifiedLicense} hasVerifiedLicense={hasVerifiedLicense} setAttachmentFileName={setAttachmentFileName} attachmentFileName={attachmentFileName} isRunningInOutlook={Office.context.host == Office.HostType.Outlook}
            hideCustomIcon={hideCustomIcon} setHideCustomIcon={setHideCustomIcon} />
          </PivotItem>
        </Pivot>}

        <Link href="https://littleappy.co/products/qr-code-generator/ideas" target="_blank" style={{ position: "fixed", bottom: 8, left: 4, right: 4, textAlign: 'center'}}>Missing a feature? Let us know about it here</Link>

        <PrimaryButton
          style={{ position: "fixed", bottom: 32, left: 4, right: 4 }}
          disabled={isLoading}
          buttonType={ButtonType.hero}
          onClick={async () => {
            var isValidData = validateData();

            if (!isValidData){
              setPivotKey('free');
              return;
            }

            setIsLoading(true);

            try { 
              await insertImage()
            } catch (reason){
              setGlobalErrorMessage(reason.message)
              setShowGlobalErrorMessage(true)
            }

            setTimeout(() => setIsLoading(false), 500);
          }
          }>
          Generate QR code
        </PrimaryButton>
      </Stack>

      <ColorPickerPanel isOpen={showColorPicker && showColorPicker == "primary"} onDismiss={() => { setShowColorPicker(null) }} onColorChange={setPrimaryColor} />
      <ColorPickerPanel isOpen={showColorPicker && showColorPicker == "secondary"} onDismiss={() => { setShowColorPicker(null) }} onColorChange={setSecondaryColor} />

      {contentCalloutVisible && (
        <ContentCallout heading={contentCalloutHeading} body={contentCalloutBody} target={contentCalloutTarget} onDismiss={() => setContentCalloutVisible(false)} />
      )}
    </div>
  );
}
