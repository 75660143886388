import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface WebUrlContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const WebUrlContentFields = (props: WebUrlContentProps) => {
    const [webUrl, setWebUrl] = React.useState("");

    useEffect(() => {
        // Set content to the web URL entered by the user
        if (webUrl) {
            props.setContent(webUrl);
        }
    }, [webUrl]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Web URL" 
                    value={webUrl} 
                    onChange={(_e, newValue) => setWebUrl(newValue)} 
                    placeholder="Enter any website URL" 
                />
            </Stack.Item>
        </Stack>
    );
};
