import * as React from "react";
import { Callout, Text } from '@fluentui/react';

export interface ContentCalloutProps {
    heading: String
    body: String
    target: String
    onDismiss: () => void
}

export const ContentCallout = (props: ContentCalloutProps) => {
    return <Callout
        role="alertdialog"
        style={{ padding: '20px' }}
        gapSpace={0}
        target={`#${props.target}`}
        onDismiss={props.onDismiss}
        setInitialFocus
    >
        <Text block variant="xLarge">
            {props.heading}
        </Text>
        <Text block variant="small">
            {props.body}
        </Text>
    </Callout>
}
