import { useState } from 'react';

// Custom Hook to store state in localStorage
function useLocalStorage(key, initialValue) {
  // Retrieve the value from localStorage, or fallback to the initial value
  const storedValue = localStorage.getItem(key);
  const parsedValue = storedValue ? storedValue : initialValue;

  // Set up the state with the initial or parsed value from localStorage
  const [value, setValue] = useState(parsedValue);

  // Update the value in both state and localStorage
  const setStoredValue = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, newValue);
  };

  return [value, setStoredValue];
}

export default useLocalStorage;
