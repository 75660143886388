import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface PhoneNumberContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const PhoneNumberContentFields = (props: PhoneNumberContentProps) => {
    // State variable for the phone number
    const [phoneNumber, setPhoneNumber] = React.useState("");

    useEffect(() => {
        // Set the content to the phone number entered by the user
        props.setContent("tel:" + phoneNumber);
    }, [phoneNumber]); // Update content whenever phone number changes

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Phone Number" 
                    value={phoneNumber} 
                    onChange={(_e, newValue) => setPhoneNumber(newValue)} 
                    placeholder="Enter phone number" 
                />
            </Stack.Item>
        </Stack>
    );
};