import * as React from "react";
import { Stack, TextField, PrimaryButton, IconButton, IStackTokens, Spinner, MessageBar, MessageBarType, DefaultButton } from '@fluentui/react';
import { Content } from './Constants';

interface PremiumOptionProps {
    isRunningInOutlook: boolean

    headline: string
    setHeadline: (string) => void
    subHeadline: string
    setSubheadline: (string) => void

    licenseKey: string
    setLicenseKey: (string) => void

    hasVerifiedLicense: boolean
    setHasVerifiedLicense: (boolean) => void

    setContentCalloutHeading: (string) => void
    setContentCalloutBody: (string) => void
    setContentCalloutTarget: (string) => void
    setContentCalloutVisible: (boolean) => void

    attachmentFileName: string
    setAttachmentFileName: (string) => void
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export const PremiumOptions = (props: PremiumOptionProps) => {
    const [emailAddress, setEmailAddress] = React.useState(localStorage.getItem('emailAddress') ?? "");
    const [customIconUrl, setCustomIconUrl] = React.useState(localStorage.getItem('customIconUrl') ?? "");

    const [licenseKeyError, setLicenseKeyError] = React.useState("");
    const [licenseKeyEmailError, setLicenseKeyEmailError] = React.useState("");

    const [isSavingSetting, setIsSavingSetting] = React.useState(false);
    const [showPremiumErrorMessage, setShowPremiumErrorMessage] = React.useState(false);
    const [premiumErrorMessage, setPremiumErrorMessage] = React.useState("");

    const [showPremiumSuccessMessage, setShowPremiumSuccessMessage] = React.useState(false);
    const [premiumSuccessMessage, setPremiumSuccessMessage] = React.useState("");

    const [customIconUrlErrorMessage, setCustomIconUrlErrorMessage] = React.useState("");

    const validateLicenseData = () => {
        if (!props.licenseKey || props.licenseKey.trim().length <= 0) {
            setLicenseKeyError("Please specify a valid license key")
            return false
        }

        setLicenseKeyError("")

        if (!emailAddress || emailAddress.trim().length <= 0) {
            setLicenseKeyEmailError("Please specify the email used to purchase the license key")
            return false
        }

        setLicenseKeyEmailError("")

        return true
    }

    return <Stack tokens={stackTokens} style={{ marginTop: 10 }}>
        {isSavingSetting && <div>
            <Spinner label="Saving..." ariaLive="assertive" labelPosition="bottom" />
        </div>}

        {!isSavingSetting && showPremiumErrorMessage && <MessageBar messageBarType={MessageBarType.error}>
            {premiumErrorMessage}
        </MessageBar>}

        {!isSavingSetting && showPremiumSuccessMessage && <MessageBar messageBarType={MessageBarType.success}>
            {premiumSuccessMessage}
        </MessageBar>}

        {!isSavingSetting && <Stack tokens={stackTokens}>
            <TextField disabled={props.hasVerifiedLicense} value={props.licenseKey} errorMessage={licenseKeyError} onChange={(_e, value) => props.setLicenseKey(value)} placeholder="License key" label="License key" />
            <TextField disabled={props.hasVerifiedLicense} value={emailAddress} errorMessage={licenseKeyEmailError} onChange={(_e, value) => setEmailAddress(value)} placeholder="Email address" label="Email address" />
            {!props.hasVerifiedLicense && <PrimaryButton style={{ width: '100%' }} onClick={() => {
                const validateResult = validateLicenseData();

                if (!validateResult) {
                    return
                }

                setIsSavingSetting(true)

                fetch('https://qrcodegenerator.api.littleappy.co/licensekey', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        licenseKey: props.licenseKey,
                        emailAddress: emailAddress
                    })
                }).then(res => {

                    if (res.status != 200) {
                        throw Error("Invalid license key");
                    }

                    setPremiumSuccessMessage("License key verified successfully");
                    setShowPremiumSuccessMessage(true);
                    setShowPremiumErrorMessage(false);
                    localStorage.setItem('licenseKey', props.licenseKey)
                    localStorage.setItem('emailAddress', emailAddress)
                    props.setHasVerifiedLicense(true);
                })
                    .catch(() => {
                        setPremiumErrorMessage("Invalid license key. Please check your license key and email address");
                        setShowPremiumErrorMessage(true);
                        setShowPremiumSuccessMessage(false);
                        localStorage.removeItem('licenseKey');
                        localStorage.removeItem('emailAddress');
                    })

                setTimeout(() => setIsSavingSetting(false), 500);
            }}>Save license</PrimaryButton>}
            {props.hasVerifiedLicense && <DefaultButton style={{ width: '100%' }} onClick={() => {
                localStorage.removeItem('licenseKey');
                localStorage.removeItem('emailAddress');
                localStorage.removeItem('customIconUrl');
                setEmailAddress(undefined);
                props.setLicenseKey(undefined);
                props.setHasVerifiedLicense(false);
                setCustomIconUrl(undefined);
            }}>Remove license</DefaultButton>}
            
            <hr />

            <Stack horizontal verticalAlign={'end'}>
                <Stack.Item grow>
                    <TextField disabled={!props.hasVerifiedLicense} value={props.headline} onChange={(_e, value) => props.setHeadline(value)} placeholder="Headline" label="Headline" />
                </Stack.Item>
                <Stack.Item align="end">
                    <IconButton id={"heading-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        props.setContentCalloutHeading(Content.AssistantCallouts.Headline.Heading)
                        props.setContentCalloutBody(Content.AssistantCallouts.Headline.Description)
                        props.setContentCalloutTarget("heading-callout")
                        props.setContentCalloutVisible(true)
                    }} />
                </Stack.Item>
            </Stack>

            <Stack horizontal verticalAlign={'end'}>
                <Stack.Item grow>
                    <TextField disabled={!props.hasVerifiedLicense} value={props.subHeadline} onChange={(_e, value) => props.setSubheadline(value)} placeholder="Subheadline" label="Subheadline" />
                </Stack.Item>
                <Stack.Item align="end">
                    <IconButton id={"subheadline-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        props.setContentCalloutHeading(Content.AssistantCallouts.SubHeadline.Heading)
                        props.setContentCalloutBody(Content.AssistantCallouts.SubHeadline.Description)
                        props.setContentCalloutTarget("subheadline-callout")
                        props.setContentCalloutVisible(true)
                    }} />
                </Stack.Item>
            </Stack>

            {props.isRunningInOutlook && <Stack horizontal verticalAlign={'end'}>
                <Stack.Item grow>
                    <TextField disabled={!props.hasVerifiedLicense} value={props.attachmentFileName} onChange={(_e, value) => props.setAttachmentFileName(value)} placeholder="qrcode" label="Attachment File Name" />
                </Stack.Item>
                <Stack.Item align="end">
                    <IconButton id={"attachmentfilename-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        props.setContentCalloutHeading(Content.AssistantCallouts.AttachmentFileName.Heading)
                        props.setContentCalloutBody(Content.AssistantCallouts.AttachmentFileName.Description)
                        props.setContentCalloutTarget("attachmentfilename-callout")
                        props.setContentCalloutVisible(true)
                    }} />
                </Stack.Item>
            </Stack>}

            <Stack horizontal verticalAlign={'end'}>
                <Stack.Item grow>
                    <TextField errorMessage={customIconUrlErrorMessage} disabled={!props.hasVerifiedLicense} value={customIconUrl} onChange={(_e, value) => setCustomIconUrl(value)} placeholder="http://your-url.com/logo.png" label="Custom icon URL" />
                </Stack.Item>
                <Stack.Item align="end">
                    <IconButton id={"customicon-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        props.setContentCalloutHeading(Content.AssistantCallouts.CustomIcon.Heading)
                        props.setContentCalloutBody(Content.AssistantCallouts.CustomIcon.Description)
                        props.setContentCalloutTarget("customicon-callout")
                        props.setContentCalloutVisible(true)
                    }} />
                </Stack.Item>
            </Stack>

            <PrimaryButton disabled={!props.hasVerifiedLicense} style={{ width: '100%' }} onClick={() => {

                if (!customIconUrl || !(customIconUrl.trim().length > 0)) {
                    setCustomIconUrlErrorMessage("Please provide a custom icon URL");
                    return;
                }

                setCustomIconUrlErrorMessage("");
                setIsSavingSetting(true)
                setShowPremiumSuccessMessage(false);
                setShowPremiumErrorMessage(false);

                fetch('https://qrcodegenerator.api.littleappy.co/qrcode/icon', {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        licenseKey: props.licenseKey,
                        emailAddress: emailAddress,
                        iconUrl: customIconUrl
                    })
                }).then(res => {
                    if (res.status != 200) {
                        throw Error("Invalid icon URL");
                    }
                    setTimeout(() => setIsSavingSetting(false), 200);
                    setPremiumSuccessMessage("Custom icon saved");
                    setShowPremiumSuccessMessage(true);
                    setShowPremiumErrorMessage(false);
                    localStorage.setItem('customIconUrl', customIconUrl)
                })
                    .catch(() => {
                        setTimeout(() => setIsSavingSetting(false), 200);
                        setPremiumErrorMessage("Error while saving custom icon. Ensure URL is valid, and publicly available. Image must be less than 2MB in size, and a JPG or PNG file.");
                        setShowPremiumErrorMessage(true);
                        setShowPremiumSuccessMessage(false);
                        localStorage.removeItem('customIconUrl');
                    })

            }}>Set icon</PrimaryButton>

            <DefaultButton disabled={!props.hasVerifiedLicense} style={{ width: '100%' }} onClick={() => {

                setIsSavingSetting(true)
                setShowPremiumSuccessMessage(false);
                setShowPremiumErrorMessage(false);

                fetch('https://qrcodegenerator.api.littleappy.co/qrcode/icon', {
                    method: 'DELETE',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        license: props.licenseKey,
                        emailAddress: emailAddress,
                    })
                }).then(res => {
                    if (res.status != 200) {
                        throw Error("Invalid icon URL");
                    }
                    setTimeout(() => setIsSavingSetting(false), 200);
                    setPremiumSuccessMessage("Custom icon removed");
                    setShowPremiumSuccessMessage(true);
                    setShowPremiumErrorMessage(false);
                    localStorage.removeItem('customIconUrl');
                    setCustomIconUrl(undefined);
                })
                    .catch((_) => {
                        setTimeout(() => setIsSavingSetting(false), 200);
                        setPremiumErrorMessage("Error while removing custom icon. Please try again later.");
                        setShowPremiumErrorMessage(true);
                        setShowPremiumSuccessMessage(false);
                    })

            }}>Delete icon</DefaultButton>
        </Stack>}
    </Stack>
}