
import { IconButton, Stack, TextField } from "@fluentui/react"
import React = require("react")
import { Content } from "../Constants";

interface GooglePlayStoreContentFieldsProps {
    content?: string;
    setContent: (string) => void;

    setContentCalloutHeading: (string) => void;
    setContentCalloutBody: (string) => void;
    setContentCalloutTarget: (string) => void;
    setContentCalloutVisible: (boolean) => void;
}

export const GooglePlayStoreContentFields = (props: GooglePlayStoreContentFieldsProps) => {
    return <Stack horizontal verticalAlign={'end'}>
        <Stack.Item grow>
            <TextField placeholder="https://play.google.com/store/apps/details?id=com.littleappyco.example" label="Play Store URL" required value={props.content} onChange={(_e, newValue) => { props.setContent(newValue) }} />
        </Stack.Item>
        <Stack.Item align="end">
            <IconButton id={"content-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                props.setContentCalloutHeading(Content.AssistantCallouts.Content.Heading)
                props.setContentCalloutBody(Content.AssistantCallouts.Content.Description)
                props.setContentCalloutTarget("content-callout")
                props.setContentCalloutVisible(true)
            }} />
        </Stack.Item>
    </Stack>
}