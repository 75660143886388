import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface LocalFileContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const LocalFileContentFields = (props: LocalFileContentProps) => {
    // State variables for the local file path and file name
    const [filePath, setFilePath] = React.useState("");
    const [fileName, setFileName] = React.useState("");

    useEffect(() => {
        // Build the local file path URL based on the input fields
        let localFileLink = `file:///${filePath.replace(/\\/g, '/')}`;

        // If fileName is provided, append it to the local file path
        if (fileName) {
            localFileLink += `/${fileName}`;
        }

        // Set the content as the local file link
        props.setContent(localFileLink);
    }, [filePath, fileName]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Local File Path" 
                    value={filePath} 
                    onChange={(_e, newValue) => setFilePath(newValue)} 
                    placeholder="Enter the file path (e.g., C:/Documents/)" 
                />
                <TextField 
                    label="File Name" 
                    value={fileName} 
                    onChange={(_e, newValue) => setFileName(newValue)} 
                    placeholder="Enter the file name (e.g., file.txt)" 
                />
            </Stack.Item>
        </Stack>
    );
};
