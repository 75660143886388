import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface TikTokContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const TikTokContentFields = (props: TikTokContentProps) => {
    const [tiktokUsername, setTikTokUsername] = React.useState("");

    useEffect(() => {
        // Construct TikTok profile URL
        if (tiktokUsername) {
            const tiktokProfileLink = `https://www.tiktok.com/@${tiktokUsername}`;
            props.setContent(tiktokProfileLink);
        }
    }, [tiktokUsername]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="TikTok Username" 
                    value={tiktokUsername} 
                    onChange={(_e, newValue) => setTikTokUsername(newValue)} 
                    placeholder="Enter TikTok username" 
                />
            </Stack.Item>
        </Stack>
    );
};