import { Link, MessageBar, MessageBarType, Stack } from "@fluentui/react"
import React = require("react")

export const PremiumContentFields = () => {
    return <Stack horizontal verticalAlign={'end'}>
       <MessageBar messageBarType={MessageBarType.warning}>
            Add a license key to generate QR codes using this content type. <br /><Link href="https://littleappy.co/products/qr-code-generator/integrations/microsoft-office?utm_medium=display&utm_source=office365App&utm_campaign=qrCodeContentType" target="_blank" style={{marginLeft: -5, marginTop: 5}} underline>Get license key</Link>
        </MessageBar>
    </Stack>
}
