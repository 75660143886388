import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface ContactDetailsProps {
    content?: string;
    setContent: (content: string) => void;
}

export const ContactDetailsFields = (props: ContactDetailsProps) => {
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [nickname, setNickname] = React.useState("");
    const [organisation, setOrganisation] = React.useState("");
    const [organisationTitle, setOrganisationTitle] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [mobilePhoneNumber, setMobilePhoneNumber] = React.useState("");
    const [workPhoneNumber, setWorkPhoneNumber] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [streetAddress, setStreetAddress] = React.useState("");
    const [houseNumber, setHouseNumber] = React.useState("");
    const [city, setCity] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [stateOrRegion, setStateOrRegion] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [note, setNote] = React.useState("");

    useEffect(() => {
        // Build the VCard content dynamically based on the input fields
        let vCard = "BEGIN:VCARD\nVERSION:4.0\n";

        if (firstName || lastName) vCard += `FN:${firstName} ${lastName}\n`;
        if (nickname) vCard += `NICKNAME:${nickname}\n`;
        if (organisation) vCard += `ORG:${organisation}\n`;
        if (organisationTitle) vCard += `TITLE:${organisationTitle}\n`;
        if (phoneNumber) vCard += `TEL:${phoneNumber}\n`;
        if (mobilePhoneNumber) vCard += `TEL;TYPE=CELL:${mobilePhoneNumber}\n`;
        if (workPhoneNumber) vCard += `TEL;TYPE=WORK:${workPhoneNumber}\n`;
        if (email) vCard += `EMAIL:${email}\n`;
        if (website) vCard += `URL:${website}\n`;
        if (streetAddress || houseNumber || city || zipCode || stateOrRegion || country) {
            vCard += `ADR:;;${houseNumber} ${streetAddress};${city};${stateOrRegion};${zipCode};${country}\n`;
        }
        if (note) vCard += `NOTE:${note}\n`;

        vCard += "END:VCARD";

        // Set the content to the VCard string
        props.setContent(vCard);
    }, [firstName, lastName, nickname, organisation, organisationTitle, phoneNumber, mobilePhoneNumber, workPhoneNumber, email, website, streetAddress, houseNumber, city, zipCode, stateOrRegion, country, note]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField label="First Name" value={firstName} onChange={(_e, newValue) => setFirstName(newValue)} />
                <TextField label="Last Name" value={lastName} onChange={(_e, newValue) => setLastName(newValue)} />
                <TextField label="Nickname" value={nickname} onChange={(_e, newValue) => setNickname(newValue)} />
                <TextField label="Organisation" value={organisation} onChange={(_e, newValue) => setOrganisation(newValue)} />
                <TextField label="Organisation Title" value={organisationTitle} onChange={(_e, newValue) => setOrganisationTitle(newValue)} />
                <TextField label="Phone Number" value={phoneNumber} onChange={(_e, newValue) => setPhoneNumber(newValue)} />
                <TextField label="Mobile Phone Number" value={mobilePhoneNumber} onChange={(_e, newValue) => setMobilePhoneNumber(newValue)} />
                <TextField label="Work Phone Number" value={workPhoneNumber} onChange={(_e, newValue) => setWorkPhoneNumber(newValue)} />
                <TextField label="Email" value={email} onChange={(_e, newValue) => setEmail(newValue)} />
                <TextField label="Website" value={website} onChange={(_e, newValue) => setWebsite(newValue)} />
                <TextField label="Street Address" value={streetAddress} onChange={(_e, newValue) => setStreetAddress(newValue)} />
                <TextField label="House Number" value={houseNumber} onChange={(_e, newValue) => setHouseNumber(newValue)} />
                <TextField label="City" value={city} onChange={(_e, newValue) => setCity(newValue)} />
                <TextField label="ZIP Code" value={zipCode} onChange={(_e, newValue) => setZipCode(newValue)} />
                <TextField label="State or Region" value={stateOrRegion} onChange={(_e, newValue) => setStateOrRegion(newValue)} />
                <TextField label="Country" value={country} onChange={(_e, newValue) => setCountry(newValue)} />
                <TextField label="Note" value={note} onChange={(_e, newValue) => setNote(newValue)} />
            </Stack.Item>
        </Stack>
    );
};