import { IColor, Panel, ColorPicker, getColorFromString, PrimaryButton } from '@fluentui/react';
import * as React from "react";

interface ColorPickerProps {
    isOpen: boolean
    onDismiss: () => void
    onColorChange: React.Dispatch<React.SetStateAction<IColor>>
}

export const ColorPickerPanel = (props: ColorPickerProps) => {
    const [color, setColor] = React.useState(getColorFromString('#ffffff')!);

    return <Panel
    headerText="Primary color picker"
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
    closeButtonAriaLabel="Close"
  >
    <ColorPicker
      color={color}
      onChange={(_e, color) => {
          setColor(color);
          props.onColorChange(color);
        }}
      alphaType={'none'}
      showPreview={false}
      // styles={colorPickerStyles}
      // The ColorPicker provides default English strings for visible text.
      // If your app is localized, you MUST provide the `strings` prop with localized strings.
      strings={{
        // By default, the sliders will use the text field labels as their aria labels.
        // Previously this example had more detailed instructions in the labels, but this is
        // a bad practice and not recommended. Labels should be concise, and match visible text when possible.
        hueAriaLabel: 'Hue',
      }}
    />
    <PrimaryButton style={{ width: "100%", marginTop: 20 }} onClick={props.onDismiss}>Select color</PrimaryButton>
  </Panel>
}