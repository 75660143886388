import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface WhatsAppContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const WhatsAppContentFields = (props: WhatsAppContentProps) => {
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [message, setMessage] = React.useState("");

    useEffect(() => {
        if (phoneNumber) {
            // Construct WhatsApp link with the phone number and optional message
            const encodedMessage = message ? `&text=${encodeURIComponent(message)}` : '';
            const whatsappLink = `https://wa.me/${phoneNumber}?${encodedMessage}`;
            props.setContent(whatsappLink);
        }
    }, [phoneNumber, message]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Phone Number (with country code)" 
                    value={phoneNumber} 
                    onChange={(_e, newValue) => setPhoneNumber(newValue)} 
                    placeholder="Enter phone number with country code" 
                />
                <TextField 
                    label="Message (Optional)" 
                    value={message} 
                    onChange={(_e, newValue) => setMessage(newValue)} 
                    placeholder="Enter message (optional)" 
                />
            </Stack.Item>
        </Stack>
    );
};
