export const Content = {
    AssistantCallouts: {
        Content: {
            Heading: "Content",
            Description: "Insert the content you want the QR code to contain. As an example, this could be a web URL or phone number."
        },
        FileFormat: {
            Heading: "File Format",
            Description: "Specify the file format in which the generated QR codes will be downloadable as."
        },
        ErrorCorrectionLevel: {
            Heading: "Error Correction Level",
            Description: "Set the error correction level capability for your QR codes. Note: The higher the error correction, the larger the QR code size will be."
        },
        Size: {
            Heading: "Size",
            Description: "Size of the QR code, in pixels. Will be used to set both the width and the height of the created QR code."
        },
        Margins: {
            Heading: "Margin",
            Description: "By enabling this option, a margin is added around the outside of the generated QR code. This is useful for allowing faster recognition of a QR code when scanning if it's to be placed near other content." 
        },
        UseCustomIcon: {
            Heading: "Hide Custom Icon",
            Description: "Enabling this toggle will generate QR codes without the custom icon, even if one has been uploaded. Normally this could only be done if the custom icon was deleted first." 
        },
        PrimaryColor: {
            Heading: "Primary Color",
            Description: "Primary color used for the QR code." 
        },
        BackgroundColor: {
            Heading: "Background Color",
            Description: "Background color for the QR code."
        },
        Headline: {
            Heading: "Headline",
            Description: "Optional heading that appears below your QR code. It appears in bold text. If text is being truncated, try increasing the size setting for your QR code. This can be useful for displaying additional information such as product names or reference IDs."
        },
        SubHeadline: {
            Heading: "Subheadline",
            Description: "Optional text that appears below your QR code and heading. If text is being truncated, try increasing the size setting for your QR code. This can be useful for displaying additional information such as product names or reference IDs."
        },
        CustomIcon: {
            Heading: "Custom Icon",
            Description: "Use our icon management tool to upload an image that will be placed in the middle of all QR codes generated using your license key across all supported platforms."
        },
        AttachmentFileName: {
            Heading: "Attachment File Name",
            Description: "Specify the file name for the QR code attachment. This is used when attaching a QR code to an email in Outlook."
        },
        ExcelCellSelection: {
            Heading: "Use Excel cell selection",
            Description: "Generate multiple QR codes in one go, by turning this toggle on and selecting a range of cells. The value of these cells will be encoded into the QR code, with the image saved into the cell in the same row and the column selected using the 'Column to insert QR codes into' setting."
        },
        QrCodeColumn: {
            Heading: "QR Code image column",
            Description: "Specify which column the generated QR codes should be inserted into. This column should be empty as images will be placed on top of these cells."
        },
        QrCodeUrlColumn: {
            Heading: "QR Code URL column",
            Description: "URLs used in the IMAGE function can be a maximum 255 characters long. By generating and inserting the URL into a separate column specified here, we can avoid this limit and use the IMAGE function to generate the QR code."
        },
        QrCodeLayoutOptions: {
            Heading: "QR Code layout option",
            Description: "'In cell' places the images in the cells (destructive, replacing existing cell content) using Excel's IMAGE function. 'Above cell' inserts a picture that is then placed over the top of the cell contents, hiding but not replacing the cell content."
        }
    }
}