import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface InstagramContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const InstagramContentFields = (props: InstagramContentProps) => {
    const [instagramUsername, setInstagramUsername] = React.useState("");

    useEffect(() => {
        // Construct Instagram profile URL
        if (instagramUsername) {
            const instagramProfileLink = `https://www.instagram.com/${instagramUsername}/`;
            props.setContent(instagramProfileLink);
        }
    }, [instagramUsername]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Instagram Username" 
                    value={instagramUsername} 
                    onChange={(_e, newValue) => setInstagramUsername(newValue)} 
                    placeholder="Enter Instagram username" 
                />
            </Stack.Item>
        </Stack>
    );
};
