import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface EmailContentDetailsProps {
    contentErrorMessage?: string;
    content?: string;
    setContent: (content: string) => void;
}

export const EmailContentDetailsFields = (props: EmailContentDetailsProps) => {
    const [receiverEmail, setReceiverEmail] = React.useState("");
    const [emailSubject, setEmailSubject] = React.useState("");
    const [emailMessage, setEmailMessage] = React.useState("");

    useEffect(() => {
        // Create the mailto link dynamically based on the input fields
        let mailtoLink = `mailto:${receiverEmail}`;

        // If email subject is provided, append it
        if (emailSubject) {
            mailtoLink += `?subject=${encodeURIComponent(emailSubject)}`;
        }

        // If email message is provided, append it to the link
        if (emailMessage) {
            mailtoLink += emailSubject ? `&body=${encodeURIComponent(emailMessage)}` : `?body=${encodeURIComponent(emailMessage)}`;
        }

        // Set the content as the mailto link
        props.setContent(mailtoLink);
    }, [receiverEmail, emailSubject, emailMessage]);

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Receiver's Email Address" 
                    value={receiverEmail} 
                    onChange={(_e, newValue) => setReceiverEmail(newValue)} 
                    placeholder="Enter the receiver's email" 
                />
                <TextField 
                    label="Email Subject" 
                    value={emailSubject} 
                    onChange={(_e, newValue) => setEmailSubject(newValue)} 
                    placeholder="Enter the subject" 
                />
                <TextField 
                    label="Email Message" 
                    value={emailMessage} 
                    onChange={(_e, newValue) => setEmailMessage(newValue)} 
                    placeholder="Enter the message body" 
                    multiline 
                    rows={5}
                />
            </Stack.Item>
        </Stack>
    );
};
