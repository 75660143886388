export const Content = {
    AssistantCallouts: {
        Content: {
            Heading: "Content",
            Description: "Insert the content you want to the QR code to contain. As an example, this could be a web URL or phone number."
        },
        FileFormat: {
            Heading: "File Format",
            Description: "Specify the file format in which the generated QR codes will be downloadable as."
        },
        ErrorCorrectionLevel: {
            Heading: "Error Correction Level",
            Description: "Set the error correction level capability for your QR codes. Note: The higher the error correction, the larger the QR code size will be."
        },
        Size: {
            Heading: "Size",
            Description: "Size of the QR code, in pixels. Will be used to set both the width and the height of the created QR code."
        },
        Margins: {
            Heading: "Margin",
            Description: "By enabling this option, a margin is added around the outside of the generated QR code. This is useful for allowing faster recognition of a QR code when scanning if it's to be placed near other content." 
        },
        PrimaryColor: {
            Heading: "Primary Color",
            Description: "Primary color used for the QR code." 
        },
        BackgroundColor: {
            Heading: "Background Color",
            Description: "Background color for the QR code."
        },
        Headline: {
            Heading: "Headline",
            Description: "Optional heading that appears below your QR code. It appears in bold text. If text is being truncated, try increasing the size setting for your QR code. This can be useful for displaying additional information such as product names or reference IDs."
        },
        SubHeadline: {
            Heading: "Subheadline",
            Description: "Optional text that appears below your QR code and heading. If text is being truncated, try increasing the size setting for your QR code. This can be useful for displaying additional information such as product names or reference IDs."
        },
        CustomIcon: {
            Heading: "Custom Icon",
            Description: "Specify a URL to a custom JPG or PNG image (max 2MB in size) to be placed in the middle of your QR codes. URL must be publicly accessible."
        },
        AttachmentFileName: {
            Heading: "Attachment File Name",
            Description: "Specify the file name for the QR code attachment. This is used when attaching a QR code to an email in Outlook."
        }
    }
}