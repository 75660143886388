import { Link, MessageBar, MessageBarType, Stack } from "@fluentui/react"
import React = require("react")

export const BarcodeContentFields = () => {
    return <Stack horizontal verticalAlign={'end'}>
       <MessageBar messageBarType={MessageBarType.warning}>
            To generate barcodes, use our Barcode Generator app for Microsoft Office 365 instead.<br /><Link href="https://littleappy.co/products/barcode-generator/integrations/microsoft-office?utm_medium=display&utm_source=office365App&utm_campaign=bannerCta" target="_blank" style={{marginLeft: -5, marginTop: 5}} underline>Find out more</Link>
        </MessageBar>
    </Stack>
}
