import { Stack, TextField } from "@fluentui/react";
import React = require("react");
import { useEffect } from "react";

interface SmsContentProps {
    content?: string;
    setContent: (content: string) => void;
}

export const SmsContentFields = (props: SmsContentProps) => {
    // State variable for the phone number
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [textMessage, setTextMessage] = React.useState("");

    useEffect(() => {
        // Set the content to the phone number entered by the user
        var message = "smsto:" + phoneNumber;

        if (textMessage){
            message += ":" + textMessage
        }

        props.setContent(message);
    }, [phoneNumber, textMessage]); // Update content whenever phone number changes

    return (
        <Stack horizontal verticalAlign={'end'}>
            <Stack.Item grow>
                <TextField 
                    label="Phone Number" 
                    value={phoneNumber} 
                    onChange={(_e, newValue) => setPhoneNumber(newValue)} 
                    placeholder="Enter phone number" 
                />
                <TextField 
                    label="Text Message" 
                    value={textMessage}
                    multiline 
                    rows={5} 
                    onChange={(_e, newValue) => setTextMessage(newValue)} 
                    placeholder="Enter an SMS message" 
                />
            </Stack.Item>
        </Stack>
    );
};