import * as React from "react";
import { getTheme, Link, DefaultButton, PrimaryButton, MessageBar, Spinner, IconButton, ButtonType, Text, Dropdown, IDropdownOption, SpinButton, Toggle, Stack, IStackTokens, getColorFromString, Pivot, PivotItem, MessageBarType } from "@fluentui/react";
import { ColorPickerPanel } from './ColorPickerPanel';
import { useEffect } from "react";
// images references in the manifest
import "../../../assets/icon-16.png";
import "../../../assets/icon-32.png";
import "../../../assets/icon-64.png";
import "../../../assets/icon-80.png";
import "../../../assets/icon-128.png";
import { ContentCallout } from "./ContentCallout";
import { Content } from "./Constants";
import { PremiumOptions } from "./PremiumOptions";
import { FreeTextContentFields } from "./Content Fields/FreeTextContentFields";
import { AppleAppStoreContentFields } from "./Content Fields/AppleAppStoreContentFields";
import { ContactDetailsFields } from "./Content Fields/ContactDetailsFields";
import { EmailContentDetailsFields } from "./Content Fields/EmailContentDetailsFields";
import { LocalFileContentFields } from "./Content Fields/LocalFileContentFields";
import { GeolocationContentFields } from "./Content Fields/GeolocationContentFields";
import { GooglePlayStoreContentFields } from "./Content Fields/GooglePlayStoreContentFields";
import { PhoneNumberContentFields } from "./Content Fields/PhoneNumberContentFields";
import { SmsContentFields } from "./Content Fields/SmsContentFields";
import { TikTokContentFields } from "./Content Fields/TikTokContentFields";
import { WebUrlContentFields } from "./Content Fields/WebUrlContentFields";
import { WhatsAppContentFields } from "./Content Fields/WhatsAppContentFields";
import { InstagramContentFields } from "./Content Fields/InstagramContentFields";
import { BarcodeContentFields } from "./Content Fields/BarcodeContentFields";
import { PremiumContentFields } from "./Content Fields/PremiumContentFields";
import ColumnSelector from "../ColumnSelector";
import useLocalStorage from "../useLocalStorage";
/* global Word */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
}

const options: IDropdownOption[] = [
  { key: 'l', text: 'Level L (~7% correction ability)' },
  { key: 'm', text: 'Level M (~15% correction ability)' },
  { key: 'q', text: 'Level Q (~25% correction ability)', selected: true },
  { key: 'H', text: 'Level H (~30% correction ability)' }
];

const fileFormatOptions: IDropdownOption[] = [
  { key: 'jpeg', text: 'JPEG', selected: true },
  { key: 'png', text: 'PNG' }
];

const excelQrCodeSelectionGenerationLocationOptions: IDropdownOption[] = [
  { key: 'aboveCell', text: 'Above cell', selected: true },
  { key: 'inCell', text: 'In cell (replaces cell contents)' }
];

const qrContentTypes: IDropdownOption[] = [
  { key: 'freeText', text: 'Text' },
  { key: 'appleAppStore', text: 'Apple App Store' },
  { key: 'barcodes', text: 'Barcodes' },
  { key: 'contact', text: 'Business Card (vCard)' },
  { key: 'email', text: 'Email' },
  { key: 'instagram', text: 'Instagram Profile' },
  { key: 'localFileUrl', text: 'Local File URL' },
  { key: 'geolocation', text: 'Map Location (Google/Apple Maps)' },
  { key: 'googlePlayStore', text: 'Google Play Store' },
  { key: 'phoneNumber', text: 'Phone Number' },
  { key: 'sms', text: 'SMS' },
  { key: 'tikTok', text: 'TikTok Profile' },
  { key: 'webUrl', text: 'Web URL' },
  { key: 'whatsApp', text: 'WhatsApp Message' }
]

const stackTokens: IStackTokens = { childrenGap: 10 };

export default function App() {
  const [content, setContent] = React.useState("");
  const [size, setSize] = useLocalStorage('imageSize', 200);
  const [enableMargin, setEnableMargin] = useLocalStorage('enableMargin', false);
  const [fileFormat, setFileFormat] = useLocalStorage('fileFormat', fileFormatOptions[0].key);
  const [errorCorrectionLevel, setErrorCorrectionLevel] = useLocalStorage('errorCorrectionLevel', options[2].key);
  const [primaryColor, setPrimaryColor] = React.useState(getColorFromString('#000')!);
  const [secondaryColor, setSecondaryColor] = React.useState(getColorFromString('#fff')!);
  const [showColorPicker, setShowColorPicker] = React.useState(null);
  const [contentType, setContentType] = useLocalStorage('contentType', qrContentTypes[0].key as string);
  const [useExcelCellSelection, setExcelCellSelection] = useLocalStorage('useExcelCellSelection', false);

  const [headline, setHeadline] = useLocalStorage('headline', "");
  const [subheadline, setSubheadline] = useLocalStorage('subheadline', "");

  const [globalErrorMessage, setGlobalErrorMessage] = React.useState("");
  const [showGlobalErrorMessage, setShowGlobalErrorMessage] = React.useState(false);

  const [hideCustomIcon, setHideCustomIcon] = React.useState(false);

  const [licenseKey, setLicenseKey] = useLocalStorage('licenseKey', "");
  const [hasVerifiedLicense, setHasVerifiedLicense] = React.useState(localStorage.getItem('licenseKey') != undefined && localStorage.getItem('emailAddress') != undefined);
  const [attachmentFileName, setAttachmentFileName] = React.useState("");

  const [selectedColumn, setSelectedColumn] = useLocalStorage("selectedExcelColumn", "1");
  const [selectedUrlColumn, setSelectedUrlColumn] = useLocalStorage("selectedExcelUrlColumn", "2");

  useEffect(() => {
    setContent("");
  }, [contentType]);

  const validateData = () => {
    setShowGlobalErrorMessage(false)
    setGlobalErrorMessage("")

    if (content == null || content.trim().length <= 0) {
      setGlobalErrorMessage("Please provide content for the QR Code")
      setShowGlobalErrorMessage(true)
      return false;
    }

    setGlobalErrorMessage("")
    return true;
  }

  async function fetchImageAsBase64(imageUrl) {
    const response = await fetch(imageUrl);
    const buffer = await response.arrayBuffer();
    const byteArray = new Uint8Array(buffer);
    let binary = '';

    // Convert the byte array to a binary string manually
    for (let i = 0; i < byteArray.length; i++) {
      binary += String.fromCharCode(byteArray[i]);
    }

    // Return the Base64-encoded string
    return btoa(binary);
    // return `data:image/png;base64,${base64}`; // Assuming the image is PNG, adjust if necessary
  }

  const insertExcelBatchImages = async () => {
    try {
      var imageLoadError = false;
      setGlobalErrorMessage("");
      setShowGlobalErrorMessage(false);
    
      await Excel.run(async (context) => {
        const range = context.workbook.getSelectedRange();
    
        range.load("values, address, rowIndex, columnIndex, columnCount"); // Load cell values, row index, and column index
    
        await context.sync();
    
        const values = range.values; // Get cell values
        const columnIndex = range.columnIndex; // Get the selected column index of the range
    
        // Ensure selectedColumn is treated as a number
        const selectedColumnIndex = parseInt(selectedColumn, 10);
    
        // Throw an error if the selection spans more than one column
        if (range.columnCount > 1) {
          throw new Error("Cannot generate QR codes as the selection includes more than one column");
        }
    
        // Check if the selection intersects with the selected column
        if (columnIndex <= selectedColumnIndex && columnIndex + range.columnCount > selectedColumnIndex) {
          throw new Error("Cannot generate QR codes as the column selection would override your data");
        }
    
        if (!values || values.length === 0) {
          throw new Error("Please select cells to generate QR codes for, or disable the cell selection option to continue. Due to Excel limitations, entire rows and columns cannot be selected.");
        }
    
        const sheet = context.workbook.worksheets.getActiveWorksheet();
    
        // Set image size (adjust these values as needed)
        const imageWidth = size;
        const imageHeight = size;
    
        for (let row = 0; row < values.length; row++) {
          const cellValue = values[row][0]; // Get the value from the first column (can be customized)
          if (!cellValue) continue;
    
          // Generate image URL (ensure it's a valid URL and returns an image)
          const imageUrl = getQrCodeUrl(cellValue);
    
          // Get the specific row from the selected range
          const cellRowIndex = range.rowIndex + row; // Calculate the row index of the selected cell
          const cell = sheet.getRangeByIndexes(cellRowIndex, parseInt(selectedColumn), 1, 1); // Always use the selected column
    
          cell.load("left, top, height, width"); // Load 'left', 'top', 'height', and 'width' properties of the cell
    
          await context.sync(); // Ensure the cell properties are loaded
    
          // Ensure that 'left' and 'top' are valid numbers
          if (cell.left == null || cell.top == null) {
            console.log(`Skipping cell at row ${cellRowIndex}, column ${selectedColumn} because left/top are invalid.`);
            continue; // Skip invalid cells
          }
    
          if (excelQrCodeSelectionGenerationLocation === "aboveCell") {
            try {
              // Fetch the image as Base64
              const base64Image = await fetchImageAsBase64(imageUrl);
    
              // Insert image and position it above the cell
              const image = sheet.shapes.addImage(base64Image);
              image.left = cell.left;
              image.top = cell.top; // Position it above the cell
              image.width = imageWidth; // Set image width
              image.height = imageHeight; // Set image height
              await context.sync();
    
              // Adjust the row height to fit the image size
              cell.format.rowHeight = imageHeight;
              cell.format.columnWidth = imageHeight;
    
              await context.sync();
            } catch (error) {
              console.log(`Error loading image for cell at row ${cellRowIndex}, column ${selectedColumn}: ${error.message}`);
              imageLoadError = true;
            }
          } else if (excelQrCodeSelectionGenerationLocation === "inCell") {
            try {
              // Insert the image URL into the column specified in the selectedUrlColumn
              const selectedUrlColumnIndex = parseInt(selectedUrlColumn, 10); // Convert the selectedUrlColumn to a column index

              const urlCell = sheet.getRangeByIndexes(cellRowIndex, selectedUrlColumnIndex, 1, 1);
              urlCell.values = [[imageUrl]]; // Insert the URL into the specified column (keeps urlCell unchanged)

              urlCell.load("address");
              await context.sync();

              // Update the current cell with the IMAGE function using the URL in urlCell
              const imageFormula = `=IMAGE(${urlCell.address}, "", 1)`;

              // Set the formula in the current cell (cell will display the image)
              cell.formulas = [[imageFormula]]; 

              // Adjust the row height to fit the image size (if needed)
              cell.format.rowHeight = parseInt(size);
              cell.format.columnWidth = parseInt(size);

              await context.sync();
            } catch (error) {
              console.log(`Error inserting image URL for cell at row ${cellRowIndex}, column ${selectedColumn}: ${error.message}`);
              imageLoadError = true;
            }
          }
        }
    
        if (imageLoadError) {
          setGlobalErrorMessage("An error occurred while loading the QR codes. Please try with a smaller selection, or try again later.");
          setShowGlobalErrorMessage(true);
        }
    
        await context.sync();
      });  
    } catch (error) {
      console.error("Error inserting images:", error);
      setGlobalErrorMessage(error.message);
      setShowGlobalErrorMessage(true);
    }
  };

  const getQrCodeUrl = (content: string) => {
    var license = licenseKey ?? "";
    return 'https://qrcodegenerator.api.littleappy.co/qrcode/basic?content=' + encodeURIComponent(content)
      + '&fileType=' + fileFormat
      + '&errorCorrectionLevel=' + errorCorrectionLevel
      + '&primaryColor=' + encodeURIComponent('#' + primaryColor.hex)
      + '&secondaryColor=' + encodeURIComponent('#' + secondaryColor.hex)
      + '&size=' + size
      + '&enableMargin=' + enableMargin
      + '&heading=' + headline
      + '&subtitle=' + subheadline
      + '&license=' + license
      + '&disableIcon=' + hideCustomIcon
      + '&platform=office'
  }


  const fetchImage = async (content: string) => {

    var url = getQrCodeUrl(content);

    return await fetch(url)
      .then(response => {
        return response.blob()
      })
      .then(async blob => {
        return new Promise((resolve) => {
          var reader = new FileReader()
          reader.onloadend = async () => {
            var image = reader.result.toString().replace(/^data:.+;base64,/, '');
            resolve(image);
          }
          reader.readAsDataURL(blob)
        })
      })
  }

  const generateImage = async (context, host) => {
    await fetchImage(content)
      .then(async (image: string) => {
        if (host == Office.HostType.Word && Office.context.requirements.isSetSupported('WordApi', '1.1')) {
          context.document.body.insertInlinePictureFromBase64(image, "End");
        } else if (host == Office.HostType.PowerPoint && Office.context.requirements.isSetSupported('ImageCoercion', '1.1')) {
          Office.context.document.setSelectedDataAsync(image, {
            coercionType: Office.CoercionType.Image
          });
        } else if (host == Office.HostType.Excel && Office.context.requirements.isSetSupported('ExcelApi', '1.9')) {
          var currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
          currentWorksheet.shapes.addImage(image);
        } else if (host == Office.HostType.Outlook && Office.context.requirements.isSetSupported('Mailbox', '1.8')) {
          const fileName = attachmentFileName.trim().length <= 0 ? Date().toString() : encodeURI(attachmentFileName);
          var fileFormatExtension: string;

          if (fileFormat == 'jpeg') {
            fileFormatExtension = 'jpg'
          } else {
            fileFormatExtension = 'png'
          }

          Office.context.mailbox.item.addFileAttachmentFromBase64Async(image, `${fileName}.${fileFormatExtension}`, { isInline: false });
          //No context to sync for Outlook
          return;
        } else {
          throw new Error("This Office client doesn't support generating QR codes. Please update to a newer version, or try using the web version.")
        }

        return await context.sync();
      })
  }

  const insertImage = async () => {
    var host = Office.context.host;
    if (host == Office.HostType.PowerPoint) {
      return PowerPoint.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Word) {
      return Word.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Excel) {
      return Excel.run((context) => generateImage(context, host));
    } else if (host == Office.HostType.Outlook) {
      return await generateImage(undefined, host);
    }

    return;
  };

  const [contentCalloutVisible, setContentCalloutVisible] = React.useState(false);
  const [contentCalloutHeading, setContentCalloutHeading] = React.useState("");
  const [contentCalloutTarget, setContentCalloutTarget] = React.useState("");
  const [contentCalloutBody, setContentCalloutBody] = React.useState("");

  const [selectedKey, setPivotKey] = React.useState("free");

  const [excelQrCodeSelectionGenerationLocation, setExcelQrCodeSelectionGenerationLocation] = useLocalStorage("excelCellLocation", "aboveCell");

  const [isLoading, setIsLoading] = React.useState(false);

  const theme = getTheme();

  return (
    <div>
      <div className="ms-Grid" dir="ltr" style={{ height: '100vh', display: 'flex', flexDirection: 'column', paddingTop: 10, backgroundColor: theme.palette.neutralLighterAlt }}>
        <Stack tokens={stackTokens} style={{
          flex: 1, // Take up remaining space
          paddingBottom: 40
        }}>
          <Text>Customise the following settings to generate your personalized QR code.</Text>

          {isLoading && <div>
            <Spinner label="Generating QR code..." ariaLive="assertive" labelPosition="bottom" />
          </div>}

          {!isLoading && showGlobalErrorMessage && <MessageBar messageBarType={MessageBarType.error}>
            {globalErrorMessage}
          </MessageBar>}

          {!isLoading && !hasVerifiedLicense && <MessageBar messageBarType={MessageBarType.warning}>
            Add a license key to setup Microsoft Word mail merge, remove the watermark, bulk download QR codes, add a custom image, headline and more. <br /><Link href="https://littleappy.co/products/qr-code-generator/integrations/microsoft-office?utm_medium=display&utm_source=office365App&utm_campaign=bannerCta" target="_blank" onClick={() => { setPivotKey('premium') }} underline>Purchase license key</Link> | <Link style={{ marginTop: 10 }} onClick={() => { setPivotKey('premium') }} underline>Set license key</Link>
          </MessageBar>}

          {!isLoading &&
            <Pivot style={{ marginBottom: 50 }} linkSize="large" selectedKey={selectedKey} onLinkClick={(pivot) => { setPivotKey(pivot.props.itemKey) }}>
              <PivotItem itemKey={'free'} headerText="Options">
                <div style={{ marginTop: 10 }}>
                  {Office.context.host == Office.HostType.Excel && <Stack horizontal verticalAlign={'end'} tokens={stackTokens}>
                    <Stack.Item grow>
                      <Toggle label="Generate QR codes from selected Excel cells" defaultChecked={useExcelCellSelection} onText="On" offText="Off" onChange={(_e, useExcelCellSelection) => { setExcelCellSelection(useExcelCellSelection) }} />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <IconButton id={"margins-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        setContentCalloutHeading(Content.AssistantCallouts.ExcelCellSelection.Heading)
                        setContentCalloutBody(Content.AssistantCallouts.ExcelCellSelection.Description)
                        setContentCalloutTarget("margins-callout")
                        setContentCalloutVisible(true)
                      }} />
                    </Stack.Item>
                  </Stack>}

                  {Office.context.host == Office.HostType.Excel && useExcelCellSelection && !hasVerifiedLicense && <MessageBar messageBarType={MessageBarType.warning}>
                    Generate QR codes in bulk by selecting rows in your Excel spreadsheet. Enter a license key to get started. <br /><Link href="https://littleappy.co/products/qr-code-generator/integrations/microsoft-office?utm_medium=display&utm_source=office365App&utm_campaign=excelCellSelectionCta" target="_blank" underline>Purchase license key</Link> | <Link style={{ marginTop: 10 }} onClick={() => { setPivotKey('premium') }} underline>Set license key</Link>
                  </MessageBar>}

                  {useExcelCellSelection && hasVerifiedLicense && Office.context.host == Office.HostType.Excel && <Stack tokens={stackTokens} style={{ marginBottom: 10 }}>

                    <ColumnSelector
                      excelQrCodeSelectionGenerationLocation={excelQrCodeSelectionGenerationLocation}
                      selectedColumn={selectedColumn}
                      setSelectedColumn={setSelectedColumn}
                      selectedUrlColumn={selectedUrlColumn}
                      setSelectedUrlColumn={setSelectedUrlColumn}
                      setContentCalloutBody={setContentCalloutBody}
                      setContentCalloutHeading={setContentCalloutHeading}
                      setContentCalloutTarget={setContentCalloutTarget}
                      setContentCalloutVisible={setContentCalloutVisible} />

                    <Stack horizontal verticalAlign={'end'}>
                      <Stack.Item grow>
                        <Dropdown
                          label="QR code layout option"
                          options={excelQrCodeSelectionGenerationLocationOptions}
                          required
                          defaultSelectedKey={excelQrCodeSelectionGenerationLocation} // Control the selected key
                          onChange={(_e, option) => { setExcelQrCodeSelectionGenerationLocation(option.key as string) }}
                        />
                      </Stack.Item>

                      <Stack.Item align="end">
                        <IconButton id={"layout-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.QrCodeLayoutOptions.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.QrCodeLayoutOptions.Description)
                          setContentCalloutTarget("layout-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                    {excelQrCodeSelectionGenerationLocation === "inCell" && <MessageBar messageBarType={MessageBarType.warning}>
                      Note: Selecting 'In Cell' will <b>replace</b> any existing content within each cell in the selected columns for both the images and QR code URLs. Ensure you backup this document before creating QR codes using this layout option as undo may not work.
                    </MessageBar>}

                  </Stack>}

                  {(!useExcelCellSelection || Office.context.host != Office.HostType.Excel) && <Stack horizontal verticalAlign={'end'}>
                    <Stack.Item grow>
                      <Dropdown
                        required
                        defaultSelectedKey={contentType}
                        label="Content Type"
                        options={qrContentTypes}
                        onChange={(_e, option) => { setContentType(option.key as string) }}
                      />
                    </Stack.Item>
                    <Stack.Item align="end">
                      <IconButton id={"fileformat-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                        setContentCalloutHeading(Content.AssistantCallouts.FileFormat.Heading)
                        setContentCalloutBody(Content.AssistantCallouts.FileFormat.Description)
                        setContentCalloutTarget("fileformat-callout")
                        setContentCalloutVisible(true)
                      }} />
                    </Stack.Item>
                  </Stack>}

                  <Stack tokens={stackTokens}>
                    {(!useExcelCellSelection || Office.context.host != Office.HostType.Excel) && <Stack.Item style={{ marginTop: 10 }}>
                      {(!["freeText", "barcodes"].includes(contentType) && !hasVerifiedLicense && <PremiumContentFields />)}

                      {contentType == "freeText" && <FreeTextContentFields
                        content={content}
                        setContent={setContent}
                        setContentCalloutBody={setContentCalloutBody}
                        setContentCalloutHeading={setContentCalloutHeading}
                        setContentCalloutTarget={setContentCalloutTarget}
                        setContentCalloutVisible={setContentCalloutVisible}
                      />}

                      {contentType == "appleAppStore" && hasVerifiedLicense && <AppleAppStoreContentFields
                        content={content}
                        setContent={setContent}
                        setContentCalloutBody={setContentCalloutBody}
                        setContentCalloutHeading={setContentCalloutHeading}
                        setContentCalloutTarget={setContentCalloutTarget}
                        setContentCalloutVisible={setContentCalloutVisible}
                      />}

                      {contentType == "barcodes" && <BarcodeContentFields />}

                      {contentType == "contact" && hasVerifiedLicense && <ContactDetailsFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "email" && hasVerifiedLicense && <EmailContentDetailsFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "localFileUrl" && hasVerifiedLicense && <LocalFileContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "geolocation" && hasVerifiedLicense && <GeolocationContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "googlePlayStore" && hasVerifiedLicense && <GooglePlayStoreContentFields
                        content={content}
                        setContent={setContent}
                        setContentCalloutBody={setContentCalloutBody}
                        setContentCalloutHeading={setContentCalloutHeading}
                        setContentCalloutTarget={setContentCalloutTarget}
                        setContentCalloutVisible={setContentCalloutVisible}
                      />}

                      {contentType == "instagram" && hasVerifiedLicense && <InstagramContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "phoneNumber" && hasVerifiedLicense && <PhoneNumberContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "sms" && hasVerifiedLicense && <SmsContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "tikTok" && hasVerifiedLicense && <TikTokContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "webUrl" && hasVerifiedLicense && <WebUrlContentFields
                        content={content}
                        setContent={setContent}
                      />}

                      {contentType == "whatsApp" && hasVerifiedLicense && <WhatsAppContentFields
                        content={content}
                        setContent={setContent}
                      />}

                    </Stack.Item>}

                    <Stack horizontal verticalAlign={'end'}>
                      <Stack.Item grow>
                        <Dropdown
                          required
                          defaultSelectedKey={fileFormat}
                          label="File format"
                          options={fileFormatOptions}
                          onChange={(_e, option) => { setFileFormat(option.key as string) }}
                        />
                      </Stack.Item>
                      <Stack.Item align="end">
                        <IconButton id={"fileformat-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.FileFormat.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.FileFormat.Description)
                          setContentCalloutTarget("fileformat-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>
                    <Stack horizontal verticalAlign={'end'}>
                      <Stack.Item grow>
                        <Dropdown
                          required
                          label="Error correction level"
                          defaultSelectedKey={errorCorrectionLevel}
                          options={options}
                          onChange={(_e, option) => { setErrorCorrectionLevel(option.key as string) }}
                        />
                      </Stack.Item>
                      <Stack.Item align="end">
                        <IconButton id={"ecc-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.ErrorCorrectionLevel.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.ErrorCorrectionLevel.Description)
                          setContentCalloutTarget("ecc-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                    <Stack horizontal verticalAlign={'end'}>
                      <Stack.Item grow>
                        <SpinButton
                          label="Size"
                          defaultValue={`${size}`}
                          min={100}
                          max={1500}
                          step={1}
                          incrementButtonAriaLabel="Increase value by 1"
                          decrementButtonAriaLabel="Decrease value by 1"
                          onChange={(_e, option) => { setSize(Number(option)) }}
                        />
                      </Stack.Item>
                      <Stack.Item align="end">
                        <IconButton id={"size-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.Size.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.Size.Description)
                          setContentCalloutTarget("size-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                    <Stack horizontal verticalAlign={'end'}>
                      <Stack.Item grow>
                        <Toggle label="Enable margin" defaultChecked={enableMargin} onText="On" offText="Off" onChange={(_e, enableMargin) => { setEnableMargin(enableMargin) }} />
                      </Stack.Item>
                      <Stack.Item align="end">
                        <IconButton id={"margins-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.Margins.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.Margins.Description)
                          setContentCalloutTarget("margins-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                    <Stack tokens={stackTokens} horizontal verticalAlign={'center'}>
                      <div style={{ width: "40px", height: "40px", background: '#' + primaryColor.hex, border: '1px solid #000' }}></div>
                      <Stack.Item grow>
                        <DefaultButton
                          text="Select primary color"
                          onClick={() => setShowColorPicker("primary")} />
                      </Stack.Item>
                      <Stack.Item>
                        <IconButton id={"primarycolor-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.PrimaryColor.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.PrimaryColor.Description)
                          setContentCalloutTarget("primarycolor-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                    <Stack tokens={stackTokens} horizontal verticalAlign={'center'}>
                      <div style={{ width: "40px", height: "40px", background: '#' + secondaryColor.hex, border: '1px solid #000' }}></div>
                      <Stack.Item grow>
                        <DefaultButton
                          text="Select background color"
                          onClick={() => setShowColorPicker("secondary")} />
                      </Stack.Item>
                      <Stack.Item>
                        <IconButton id={"bgcolor-callout"} iconProps={{ iconName: 'info' }} onClick={() => {
                          setContentCalloutHeading(Content.AssistantCallouts.BackgroundColor.Heading)
                          setContentCalloutBody(Content.AssistantCallouts.BackgroundColor.Description)
                          setContentCalloutTarget("bgcolor-callout")
                          setContentCalloutVisible(true)
                        }} />
                      </Stack.Item>
                    </Stack>

                  </Stack>
                </div>
              </PivotItem>
              <PivotItem itemKey={'premium'} headerText="Additional Options">
                <PremiumOptions headline={headline} setHeadline={setHeadline} subHeadline={subheadline} setSubheadline={setSubheadline} setContentCalloutBody={setContentCalloutBody} setContentCalloutHeading={setContentCalloutHeading}
                  setContentCalloutTarget={setContentCalloutTarget} setContentCalloutVisible={setContentCalloutVisible} setLicenseKey={setLicenseKey} licenseKey={licenseKey}
                  setHasVerifiedLicense={setHasVerifiedLicense} hasVerifiedLicense={hasVerifiedLicense} setAttachmentFileName={setAttachmentFileName} attachmentFileName={attachmentFileName} isRunningInOutlook={Office.context.host == Office.HostType.Outlook}
                  hideCustomIcon={hideCustomIcon} setHideCustomIcon={setHideCustomIcon} />
              </PivotItem>
            </Pivot>}

        </Stack>
      </div>

      <div style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #ccc',
        boxSizing: 'border-box',
      }}>
        <Stack tokens={stackTokens} style={{ backgroundColor: theme.palette.neutralLighterAlt, padding: 10 }}>
          <PrimaryButton
            disabled={isLoading || (!["freeText", "barcodes"].includes(contentType) && !hasVerifiedLicense) || (!hasVerifiedLicense && useExcelCellSelection && Office.context.host === Office.HostType.Excel)}
            buttonType={ButtonType.hero}
            onClick={async () => {
              try {
                setGlobalErrorMessage("")
                setShowGlobalErrorMessage(false)

                if (hasVerifiedLicense && useExcelCellSelection && Office.context.host == Office.HostType.Excel) {
                  setIsLoading(true);
                  await insertExcelBatchImages();
                  setIsLoading(false);
                } else {
                  setIsLoading(true);
                  var isValidData = validateData();

                  if (!isValidData) {
                    setPivotKey('free');
                    setIsLoading(false);
                    return;
                  }

                  await insertImage();
                  setIsLoading(false);
                }
              } catch (reason) {
                setGlobalErrorMessage(reason.message)
                setShowGlobalErrorMessage(true)
              }

              setTimeout(() => setIsLoading(false), 500);
            }
            }>
            Generate QR code
          </PrimaryButton>
          <Link href="https://littleappy.co/products/qr-code-generator/ideas" target="_blank" style={{ textAlign: 'center' }}>Missing a feature? Let us know about it here</Link>
        </Stack>
      </div>

      <ColorPickerPanel isOpen={showColorPicker && showColorPicker == "primary"} onDismiss={() => { setShowColorPicker(null) }} onColorChange={setPrimaryColor} />
      <ColorPickerPanel isOpen={showColorPicker && showColorPicker == "secondary"} onDismiss={() => { setShowColorPicker(null) }} onColorChange={setSecondaryColor} />

      {contentCalloutVisible && (
        <ContentCallout heading={contentCalloutHeading} body={contentCalloutBody} target={contentCalloutTarget} onDismiss={() => setContentCalloutVisible(false)} />
      )}
    </div>
  );
}
